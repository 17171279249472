import React from 'react'
import { graphql } from 'gatsby'
import Layout from '../components/Layout'
import styled from 'styled-components'
import Img from 'gatsby-image'
import JustComments from '../components/JustComments'
import CTA from './CTA'
import Helmet from 'react-helmet'
import PropTypes from 'prop-types'
import '../designs/dist/css/bootstrap.min.css'
import '../designs/dist/js/slick/slick.css'
import '../designs/dist/css/main.min.css'

export default class Template extends React.Component {
  state = {
    menuVisible: false,
  }

  toggleMenu = () => {
    this.setState({ menuVisible: !this.state.menuVisible })
  }

  render() {
    const { markdownRemark } = this.props.data // data.markdownRemark holds our post data
    const { frontmatter, html } = markdownRemark
    return (
      <>
        <Layout
          menuVisible={this.state.menuVisible}
          toggleMenu={this.toggleMenu}
        >
          <Helmet
            meta={[
              { name: 'og:title', content: frontmatter.title },
              { name: 'og:description', content: frontmatter.description },
              {
                name: 'og:image',
                content:
                  'https://thebrain.pro' +
                  frontmatter.featuredImage.childImageSharp.sizes.src,
              },
            ]}
          />
          <Wrapper>
            <div className="blog-post-container">
              <div className="blog-post">
                <h3>{frontmatter.title}</h3>
                <div>
                  <label className="blog-date">{frontmatter.date}</label>
                </div>
                {frontmatter.featuredImage && (
                  <Img
                    className="coverImage"
                    sizes={frontmatter.featuredImage.childImageSharp.sizes}
                  />
                )}
                <br />
                <div
                  className="blog-post-content"
                  dangerouslySetInnerHTML={{ __html: html }}
                />
              </div>

              <CTA type={frontmatter.cta} />

              {frontmatter.comments && <JustComments />}
            </div>
          </Wrapper>
        </Layout>
      </>
    )
  }
}

Template.propTypes = {
  data: PropTypes.shape({
    frontmatter: PropTypes.any,
    html: PropTypes.any,
  }).isRequired,
}

export const pageQuery = graphql`
  query($path: String!) {
    markdownRemark(frontmatter: { path: { eq: $path } }) {
      html
      frontmatter {
        date(formatString: "MMMM DD, YYYY")
        path
        title
        comments
        cta
        description
        featuredImage {
          childImageSharp {
            sizes(maxWidth: 740) {
              ...GatsbyImageSharpSizes
            }
          }
        }
      }
    }
  }
`
const Wrapper = styled.div`
  background-color: white;
  color: #145a6f;
  min-height: calc(100vh - 250px);
  padding-top: 50px;
  padding-bottom: 20px;
  margin: auto;

  .blog-post-container {
    margin: auto;
    margin-top: 100px;
    width: 760px;
    padding: 20px;
    max-width: 100%;

    ul {
      margin-left: 20px;
    }

    li {
      list-style-type: circle;
      margin-top: 1em;
      line-height: 1.6em;
      font-size: 1.1em;
    }

    a {
      color: #25d6fb;
    }

    h6 {
      color: #777;
    }

    p {
      margin-top: 1em;
      line-height: 1.6em;
      font-size: 1.1em;
    }
    strong {
      color: #0094cf;
      text-transform: uppercase;
    }
  }

  .styled-name {
    font-weight: bold;
    color: rgb(0, 216, 255);
    span {
      color: rgb(0, 182, 214);
    }
  }

  .about {
    padding: 30px;
    background-color: #212529;
    color: #a3a8ab;
    margin-top: 50px;
  }

  .cta-link-wrapper {
    display: flex;
    justify-content: center;
    margin-top: 50px;
    margin-bottom: 100px;
    a {
      color: white;
    }
  }

  img {
    max-width: 100%;
  }

  .blog-date {
    position: relative;
    top: 30px;
    z-index: 10;
    background-color: #001d29;
    color: #a3a8aa;
    font-size: 87.5%;
    font-weight: 400;
    padding: 10px;
  }
`
