import Button from '../components/_shared/Button'
import React from 'react'

const CTA = props =>
  props.type === 'apps' ? (
    <div>
      <div className="about">
        Here at{' '}
        <span className="styled-name">
          <span>THE</span>BRAIN SOFTWARE<span>HOUSE</span>
        </span>
        , we know how integral mobile apps will be to the future of business.
        That&#39;s why we provide 1 month app delivery to you, no questions
        asked, or your money back. Our team of accredited industry development
        professionals, engineers, and designers work together to create a
        cutting-edge, eye-catching, and reliable mobile app that is right for
        you and your business.
      </div>

      <div className={'cta-link-wrapper'}>
        <Button>
          <a href="http://getyourapp.thebrain.pro">
            Schedule a free consultation with us today
          </a>
        </Button>
      </div>
    </div>
  ) : (
    <div>
      <div className="about">
        Here at{' '}
        <span className="styled-name">
          <span>THE</span>BRAIN SOFTWARE<span>HOUSE</span>
        </span>
        , we realize that most of the application costs is actually in the short
        and long term maintenance, not the initial production. Majority of
        software projects start with a fast pace and quickly turn into costly
        “big ball of mud”, to eventually die or start from scratch. To that end
        we are experts in testing automation. We love everything
        testing-related. We author and contribute to many testing-related Open
        Source tools used widely by the programming community. If you or your
        team needs a hand, we would love to help. Nothing excites us more than
        to jump into an unknown codebase and bring order to the chaos. We will
        pair up with you and your team to show you the path.
      </div>
    </div>
  )

export default CTA
