import React from 'react'

export default class JustComments extends React.Component {
  constructor(...args) {
    super(...args)
    this.ref = React.createRef()
  }
  render() {
    return (
      <div
        ref={this.ref}
        className="just-comments"
        data-apikey={process.env.GATSBY_JUST_COMMENTS_API_KEY}
      />
    )
  }
  componentDidMount() {
    const s = document.createElement('script')
    s.src = '//just-comments.com/w.js'
    s.setAttribute('data-timestamp', +new Date())
    this.ref.current.appendChild(s)
  }
}
